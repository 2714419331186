import { AxiosInstance, getError } from 'api/AxiosInstance';
import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAuth = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token') || '';

  const checkExpiredToken = () => {
    const { expiry } = JSON.parse(token);
    const now = new Date().getTime();
    if (now > expiry) {
      localStorage.removeItem('token');
    }
  };

  const decodeToken = () => {
    if (!token) return;
    checkExpiredToken();
    const { value } = JSON.parse(token);
    const { data: tokenDecode } = jwtDecode<{ data: string }>(value);
    return JSON.parse(tokenDecode);
  };

  const authInfo = decodeToken();

  const onLogin = async ({
    user,
    password,
    authtype,
  }: {
    user: string;
    password: string;
    authtype: string;
  }) => {
    try {
      setLoading(true);
      const { data } = await AxiosInstance().post(`/${authtype}`, {
        user,
        password,
      });
      localStorage.setItem(
        'token',
        JSON.stringify({
          value: data.token,
          expiry: data.expiresIn,
        }),
      );
      setLoading(false);
      navigate('/dashboard');
    } catch (e) {
      const error = getError(e);
      setError(error);
      setLoading(false);
    }
  };

  const onLogout = async () => {
    try {
      setLoading(false);
      await AxiosInstance().post('/logout', authInfo);
      localStorage.removeItem('token');
      setLoading(false);
      navigate('/');
    } catch (e) {
      const error = getError(e);
      setLoading(false);
      setError(error);
    }
  };

  return {
    onLogin,
    onLogout,
    token,
    error,
    loading,
    authInfo,
  };
};
