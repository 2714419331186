import React, { useState } from 'react';
import VisibilityOff from '../assets/icons/visibility-off.svg';
import VisibilityOn from '../assets/icons/visibility-on.svg';
import { useController } from 'react-hook-form';

type InputProps = {
  name: string;
  label: string;
  type?: string;
};

type LabelProps = {
  labelFor: string;
  children: any;
  labelStyles?: string;
};
const Label: React.FC<LabelProps> = ({ labelFor, children, labelStyles }) => {
  return (
    <label
      className={`absolute left-0 text-sm peer-focus:-top-3.5 transition-all ${labelStyles}`}
      htmlFor={labelFor}>
      {children}
    </label>
  );
};

const InputWrapper: React.FC<{ children: any }> = ({ children }) => {
  return <div className="relative">{children}</div>;
};

const inputStyles =
  'peer h-10 w-full border-b-2 border-[--input-border] focus:outline-none text-sm text-[--input-text-color]';

const Text: React.FC<InputProps> = ({ name, label, type = 'text' }) => {
  const { field } = useController({ name });

  return (
    <InputWrapper>
      <input required className={inputStyles} {...field} type={type} />
      <Label labelFor={name} labelStyles={field.value ? '-top-3.5' : 'top-2'}>
        {label}
      </Label>
    </InputWrapper>
  );
};

const Password: React.FC<InputProps> = ({ name, label }) => {
  const [hidden, setHidden] = useState(true);
  const { field } = useController({ name });

  return (
    <InputWrapper>
      <input
        required
        type={hidden ? 'password' : 'text'}
        className={inputStyles}
        {...field}
      />
      <div className="absolute right-0 top-2">
        <button onClick={() => setHidden(!hidden)}>
          <img src={hidden ? VisibilityOff : VisibilityOn} />
        </button>
      </div>
      <Label labelFor={name} labelStyles={field.value ? '-top-3.5' : 'top-2'}>
        {label}
      </Label>
    </InputWrapper>
  );
};

export const Input = {
  Text,
  Password,
};
