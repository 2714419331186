import _ from 'lodash';
import React from 'react';

export const statusStyles = {
  CREATED: 'bg-cyan-500',
  PROCESSING: 'bg-yellow-500',
  DONE: 'bg-teal-500',
};

export const TagStatus: React.FC<{ status: string }> = ({ status }) => {
  const statusBg = _.get(statusStyles, status);

  return (
    <span className={`flex justify-center p-2 rounded text-white ${statusBg}`}>
      {status}
    </span>
  );
};
