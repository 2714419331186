import React from 'react';

type SelectProps = {
  options: [
    {
      label: string;
      value: string;
    },
  ];
  placeholder: string;
};

export const Select: React.FC<SelectProps> = ({ options, placeholder }) => {
  return (
    <select
      className="select select-bordered border-[--select-border] text-black"
      defaultValue={placeholder}>
      <option value={placeholder} disabled>
        {placeholder}
      </option>
      {options.map(({ value, label }, index) => (
        <option key={index} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};
