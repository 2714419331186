import React, { createContext, useContext, useState } from 'react';

type AppContextProps = {
  overlay: any;
  setOverlay: any;
};

export const AppContext = createContext<AppContextProps>({} as any);

export const AppProvider: React.FC<{ children: any }> = ({ children }) => {
  const [overlay, setOverlay] = useState();

  const context = {
    overlay,
    setOverlay,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
