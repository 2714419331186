import { AxiosInstance } from 'axios';

export const authInter = (config: any) => {
  const auth = localStorage.getItem('token');

  if (!auth){
    const confgAuth: any = {
    ...config,
    
  };
    return confgAuth;
  } 

  const { value } = JSON.parse(auth || '');

  const newConfig: any = {
    ...config,
    headers: { Authorization: 'Bearer '+value},
  };

  return newConfig;
};

export const setInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(authInter);
  return axiosInstance;
};
