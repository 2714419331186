import React, { ReactNode, useState } from 'react';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';

export const ColapsableRow: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [colapsed, setColapsed] = useState(false);

  return (
    <>
      <tr className="p-4 border-b-2">
        {children}
        <td className="p-4">
          <button
            className="flex justify-center w-full text-primary"
            onClick={() => setColapsed(!colapsed)}>
            <DocumentMagnifyingGlassIcon className="h-6 w-6 text-gray-800" />
          </button>
        </td>
      </tr>
      <tr className={`transition-all ease-out ${colapsed ? '' : 'hidden'}`}>
        <td colSpan={7}>
          <table className="w-full">
            <thead>
              <tr className="bg-gray-200">
                <td className="p-2">Nº Pedido</td>
                <td className="p-2">Item</td>
                <td className="p-2">Ref. Fornecedor</td>
                <td className="p-2">Material</td>
                <td className="p-2">Qt. Etiquetas</td>
                <td className="p-2">Etiquetas</td>
                <td className="p-2">Status</td>
              </tr>
              <tr>
                <td className="p-2">123</td>
                <td className="p-2">Teste</td>
                <td className="p-2">Fornecedor</td>
                <td className="p-2">Material teste</td>
                <td className="p-2">2</td>
                <td className="p-2"></td>
                <td className="p-2">aprovado</td>
              </tr>
            </thead>
          </table>
        </td>
      </tr>
    </>
  );
};
