import './App.css';

import { MenuType, Overlay, Sidebar } from 'components';
import React from 'react';
import { Outlet } from 'react-router';
import { useAuth } from 'AuthHook';
import { useAppContext } from 'AppContext';
import { Footer } from 'components/Footer';
import { startMock } from 'mock/server';

const sideMenu: MenuType[] = [
  {
    name: 'Etiquetas',
    path: '/dashboard',
    icon: 'portal',
  },
];

const { REACT_APP_ENV } = process.env;
if (REACT_APP_ENV !== 'production') {
  startMock();
}

function App() {
  const { authInfo } = useAuth();
  const { overlay } = useAppContext();

  return (
    <div className="flex flex-col w-full h-screen">
      <Overlay>{overlay}</Overlay>
      <div className="flex grow">
        <Sidebar menuItems={sideMenu}>
          <div className="flex p-4 items-center">
            <div className="flex flex-col ml-2">
              <span className="text-sm">{authInfo?.txt_nome_fantasia}</span>
              <span className="text-xs">Portal de etiquetas</span>
            </div>
          </div>
        </Sidebar>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
