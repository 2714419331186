import React from 'react';

export const ErrorMessage: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <div className="alert alert-error shadow-lg gap-0">
      <div>
        <span className="text-sm">{message}</span>
      </div>
      <div className="flex flex-col items-end"></div>
    </div>
  );
};
