export const tagRoutes = (mirage: any) => {
  mirage.get('/tag/listFiles', (_schema: any, request: any) => {
    const { limit } = request.queryParams;

    let tags: any = [];

    for (let i = 0; i <= limit; i++) {
      tags = [
        ...tags,
        {
          id: Math.floor(Math.random() * 1000),
          orderCode: Math.floor(Math.random() * 1000),
          items: 'Teste',
          material: 'Teste material',
          qtde_etiq: '12',
          etiqueta: 'CABIDE BOTTOM B36CZ CINZA',
          status: 'CREATED',
          dataGeracao: '2024-12-19T16:23:45.847Z',
        },
      ];
    }
    return {
      tags,
      offsetSize: 10,
    };
  });

  mirage.get('/tag/listTag/:orderCode', (_schema: any, request: any) => {
    const { orderCode } = request.params;

    let tags: any = [];

    tags = [
      ...tags,
      {
        id: Math.floor(Math.random() * 1000),
        orderCode: orderCode,
        items: 'Teste',
        material: 'Teste material',
        qtde_etiq: '12',
        etiqueta: 'CABIDE BOTTOM B36CZ CINZA',
        status: 'CREATED',
        dataGeracao: '2024-12-19T16:23:45.847Z',
      },
    ];

    return {
      tags,
      offsetSize: 10,
    };
  });
};
