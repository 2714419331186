import React from 'react';

export const Checkbox: React.FC<{ name: string }> = ({ name }) => {
  return (
    <>
      <input
        id={name}
        type="checkbox"
        className="appearance-none w-5 h-5 border-gray-300 border-2 border-[--checkbox-border] rounded-full  checked:bg-primary"
      />
    </>
  );
};
