import React, { ReactNode } from 'react';
import { Icon, IconType } from 'components';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'AuthHook';

export type MenuType = {
  name: string;
  icon?: IconType;
  path: string;
};

type SidebarProps = {
  children?: ReactNode;
  menuItems: MenuType[];
};

export const Sidebar: React.FC<SidebarProps> = ({ children, menuItems }) => {
  const { pathname } = useLocation();
  const { onLogout } = useAuth();

  return (
    <div className="flex flex-col bg-[--sidebar-bg] w-72">
      {children}
      <nav className="mt-16">
        <span className="text-sm m-4">Menu</span>
        <ul className="text-sm bg-[--sidebar-nav-bg] mt-4 text-white">
          {menuItems.map(({ name, path, icon }, index) => (
            <li key={index}>
              <Link
                to={path}
                className={`flex p-4 items-center justify-between ${
                  pathname === path ? 'bg-primary pr-0' : ''
                }`}>
                {icon && <Icon name={icon} />}

                {name}
                <div className="ml-2">
                  {pathname != path ? (
                    <Icon name="chevronright" />
                  ) : (
                    <Icon name="triangleleft" />
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex grow items-end p-2">
        <button
          className="bg-transparent w-full h-12  text-white border-solid border border-white rounded-md leading-6"
          title="Sair"
          onClick={() => {
            onLogout();
          }}>
          <div className="flex items-center justify-center">sair</div>
        </button>
      </div>
    </div>
  );
};
