import React from 'react';

type ButtomProps = {
  title: string;
  onClick?: any;
  type?: any;
  children?: any;
};

const Primary: React.FC<ButtomProps> = ({ title, onClick, type, children }) => {
  return (
    <button
      className="bg-primary p-2 w-full rounded-md text-sm hover:opacity-0.5 text-white leading-6"
      type={type || 'button'}
      onClick={onClick}>
      <div className="flex items-center justify-center">
        {children}
        {title}
      </div>
    </button>
  );
};

const Ghost: React.FC<ButtomProps> = ({ title, onClick, type }) => {
  return (
    <button
      className="bg-white p-2 w-full text-sm text-black leading-6 border-solid border-2 border-black rounded-md bg:hover-sand-400"
      type={type || 'button'}
      onClick={onClick}>
      {title}
    </button>
  );
};

export const Button = { Primary, Ghost };
