import { useAppContext } from 'AppContext';
import React from 'react';
import { Button } from 'components';

import tagExample from 'assets/images/tag-example.png';

export const TagViewer: React.FC = () => {
  const { setOverlay } = useAppContext();

  return (
    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div className="flex flex-col bg-white p-4">
          <div className="flex w-48">
            <img src={tagExample} alt="etiqueta exemplo" />
          </div>
          <div className="px-4 py-3 w-24 self-end">
            <Button.Ghost
              type="button"
              onClick={() => setOverlay(null)}
              title="fechar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
