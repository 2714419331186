import React from 'react';

import DownloadIcon from 'assets/icons/download.svg';
import DownloadIconInactive from 'assets/icons/download-inactive.svg';
import { StatusType } from '../Types';

type DownloadProps = {
  status: StatusType;
  onDownload: Function;
};

export const Download: React.FC<DownloadProps> = ({ status, onDownload }) => {
  return (
    <>
      {status === 'DONE' ? (
        <button
          className="flex justify-center w-full text-primary"
          onClick={() => onDownload()}>
          <img className="mr-2" src={DownloadIcon} />
          download
        </button>
      ) : (
        <span className="flex justify-center text-zinc-300">
          <img className="mr-2" src={DownloadIconInactive} />
          download
        </span>
      )}
    </>
  );
};
