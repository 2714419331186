import { apiUrl } from 'api/AxiosInstance';
import { createServer } from 'miragejs';
import { authRoutes } from './auth';
import { tagRoutes } from './tags';

export const startMock = () => {
  createServer({
    routes() {
      this.urlPrefix = apiUrl.dev;
      authRoutes(this);
      tagRoutes(this);
    },
  });
};
