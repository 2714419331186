import _ from 'lodash';
import React from 'react';

import ChevronRigthIcon from '../assets/icons/chevron-right.svg';
import PortalIcon from '../assets/icons/portal.svg';
import TriangleLeftIcon from '../assets/icons/triangle-left.svg';
import ExitIcon from '../assets/icons/exit.svg';

const icons = {
  portal: PortalIcon,
  chevronright: ChevronRigthIcon,
  triangleleft: TriangleLeftIcon,
  exit: ExitIcon,
};

export type IconType = keyof typeof icons;

export const Icon: React.FC<{ name: IconType }> = ({ name }) => {
  const icon = _.get(icons, name);
  return <img src={icon} />;
};
