import React, { ButtonHTMLAttributes } from 'react';

type ButtomProps = {
  onClick?: any;
  children?: any;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Primary: React.FC<ButtomProps> = (
  { title, onClick, children },
  props,
) => {
  return (
    <button
      className="bg-primary p-2 w-full rounded-md text-sm hover:opacity-0.5 text-white leading-6"
      onClick={onClick}
      {...props}>
      <div className="flex items-center justify-center">
        {children}
        {title}
      </div>
    </button>
  );
};

const Ghost: React.FC<ButtomProps> = ({ title, onClick }, props) => {
  return (
    <button
      className="bg-white p-2 w-full h-10 flex items-center text-sm text-black leading-6 border-solid border-2 box-border border-black rounded-md bg:hover-sand-400"
      onClick={onClick}
      {...props}>
      {title}
    </button>
  );
};

export const Button = { Primary, Ghost };
