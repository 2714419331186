import _ from 'lodash';
import React from 'react';

import ChevronRigthIcon from '../assets/icons/chevron-right.svg';
import PortalIcon from '../assets/icons/portal.svg';
import TriangleLeftIcon from '../assets/icons/triangle-left.svg';
import ExitIcon from '../assets/icons/exit.svg';

import { ClipboardIcon } from '@heroicons/react/24/outline';

const icons = {
  portal: PortalIcon,
  chevronright: ChevronRigthIcon,
  triangleleft: TriangleLeftIcon,
  exit: ExitIcon,
  orders: <ClipboardIcon className="text-white w-6 h-6" />,
};

export type IconType = keyof typeof icons;

export const Icon: React.FC<{ name: IconType }> = ({ name }) => {
  const icon = _.get(icons, name);

  if (typeof icon === 'string') return <img src={icon} />;

  return icon;
};
