import { Pagination } from '@nextui-org/pagination';
import { AxiosInstance, getError } from 'api/AxiosInstance';
import { useAppContext } from 'AppContext';
import { useAuth } from 'AuthHook';
import { Button, ErrorMessage, Loading } from 'components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';

import { Download } from './components/Download';
import { TagStatus } from './components/TagStatus';
import { Tag } from './Types';
import { TagViewer } from './components/TagViewer';

export const DashboardRoute = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { setOverlay } = useAppContext();

  const {
    authInfo: { codFornecedor },
  } = useAuth();

  const getTags = async () => {
    const limit = 10;

    try {
      setLoading(true);
      const api = await AxiosInstance();
      const {
        data: { tags, offsetSize },
      } = await api.get<any, { data: { tags: Tag[]; offsetSize: number } }>(
        `/tag/listFiles/?supplierCode=${codFornecedor}&limit=${limit}&offset=${page}`,
      );

      setTags(tags);
      setPageSize(offsetSize);
      setLoading(false);
    } catch (e) {
      const errorMessage = getError(e);
      setError(errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  const handleDownload = async (tagId: string) => {
    try {
      setLoading(true);
      const api = await AxiosInstance();
      const { data } = await api.get(`/tag/downloadFile/${tagId}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'arraybuffer',
      });
      const blob = new Blob([data], { type: 'application/zip' });
      console.log(blob);
      setLoading(false);
      saveAs(blob);
    } catch (e) {
      const errorMessage = getError(e);
      setError(errorMessage);
      setLoading(false);
    }
  };

  const handleShowDocument = () => {
    setOverlay(<TagViewer />);
  };

  return (
    <div className="flex w-full ">
      <div className="flex flex-col items-center bg-[--home-bg] p-8 w-full">
        <div className="card flex-row w-full bg-white rounded-lg shadow my-4">
          <div className="p-6">
            <h2 className="card-title mb-8 text-black">Portal de etiquetas</h2>
            <p className="text-zinc-500">
              Esta página permite a{' '}
              <span className="text-blue-500">geração e download</span> dos
              arquivos contendo os dados para impressão de etiquetas através do
              sistema fornecido pela Riachuelo
            </p>
          </div>
        </div>
        <div className="card w-full bg-white rounded-lg p-6 shadow">
          <div className="flex justify-end items-center flex-col mb-4 lg:flex-row">
            <div>
              <Button.Ghost
                title="Visualizar layout de etiquetas"
                onClick={handleShowDocument}
              />
            </div>
          </div>
          {loading && <Loading />}
          <div className="overflow-scroll lg:overflow-auto relative">
            {!_.isEmpty(tags) ? (
              <table className="w-full">
                <thead className="text-center text-sm text-[--table-head-text] bg-[--table-head-bg] rounded">
                  <tr>
                    <td className="p-2">Nº Pedido</td>
                    <td className="p-2">Item</td>
                    <td className="p-2">Material</td>
                    <td className="p-2">Qt. Etiquetas</td>
                    <td className="p-2">Etiquetas</td>
                    <td className="p-2">Status</td>
                    <td className="p-2">Baixar Etiqueta</td>
                  </tr>
                </thead>
                <tbody className="text-center text-sm text-black">
                  {tags.map((tag: Tag, index: number) => (
                    <tr className="p-4 border-b-2" key={index}>
                      <td className="p-4 text-orange-500">{tag.orderCode}</td>
                      <td className="p-4">{tag.items}</td>
                      <td className="p-4">{tag.material}</td>
                      <td className="p-4">{parseInt(tag.qtde_etiq)}</td>
                      <td className="p-4">{tag.etiqueta}</td>
                      <td className="p-4">
                        <TagStatus status={tag.status} />
                      </td>
                      <td className="p-4">
                        <Download
                          status={tag.status}
                          onDownload={() => handleDownload(tag.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center">
                <p>Sem etiquetas para exibição</p>
              </div>
            )}

            {error && (
              <div className="flex w-full mt-12">
                <ErrorMessage message={error} />
              </div>
            )}
          </div>
        </div>
        {pageSize > 1 && (
          <div className="flex mt-4 place-self-start">
            <Pagination
              showControls
              total={pageSize}
              initialPage={page}
              onChange={page => {
                setPage(page);
                getTags();
              }}
              classNames={{
                wrapper: 'gap-2 h-8',
                item: 'w-8 h-8 text-small rounded bg-transparent bg-white text-black',
                cursor:
                  'w-8 h-8 bg-[--pagination-bg-active] text-white font-bold',
                chevronNext: 'rotate-180',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
