import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { setInterceptors } from './Interceptors';

type AxiosInstanceProps = {
  config: AxiosRequestConfig;
  timeout?: number;
};

export const apiUrl = {
  dev: 'https://inova-node-api-proxy.k8s-corp.uat.riachuelo.net.br/v1',
  prod: 'https://api.rchlo.io/bkoEtiq/v1'

};

export const AxiosInstance = (props?: AxiosInstanceProps) => {
  const baseURL = apiUrl.prod;
  const timeout = props?.timeout || 1000 * 60;
  const config = { baseURL, timeout, ...props?.config };
  const instance = Axios.create(config);

  return setInterceptors(instance);
};

export const getError = (error: AxiosError<{ msg: string }>) => {
  return error.response?.data.msg || 'Erro na solicitação';
};
