import { ErrorMessage } from './ErrorMessage';

export const loading = ({ appContext }: any) => {
  const { setOverlay } = appContext;

  return {
    show: () =>
      setOverlay(<span className="loading loading-spinner loading-lg"></span>),
    hide: () => setOverlay(null),
  };
};

export const error = ({ appContext }: any) => {
  const { setOverlay } = appContext;
  return {
    show: (error?: any) => {
      setOverlay(<ErrorMessage message={error?.msg} />);
    },
    hide: () => setOverlay(null),
  };
};

export const overlay = {
  loading,
  error,
};
