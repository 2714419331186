import App from 'App';
import { AppProvider } from 'AppContext';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DashboardRoute } from './dashboard/Dashboard';
import { LoginRoute } from './login/Login';
import { useAuth } from 'AuthHook';

const ProtectedRoute: React.FC<{ children: any }> = ({ children }) => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to="/" />;
  }
  return children;
};

export const AppRoutes = () => {
  return (
    <AppProvider>
      <Routes>
        <Route index element={<LoginRoute />} />
        <Route path="/" element={<App />}>
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <DashboardRoute />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AppProvider>
  );
};
