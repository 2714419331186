import jwt from 'jwt-encode';
import { addHours } from './utils';

export const authRoutes = (mirage: any) => {
  mirage.post('/authbr', () => {
    const supplierInfo = {
      codFornecedor: 145,
      txt_nome: 'ORIENT RELOGIOS AMAZONIA LTDA',
      num_cgc: '05830195000110',
      txt_nome_fantasia: 'ORIENT RELOGIOS AMAZONIA LTDA',
    };
    const hh = addHours(new Date(), 8);
    const token = jwt(
      {
        data: JSON.stringify(supplierInfo),
        iat: Date.now(),
        exp: hh,
      },
      '',
    );

    return { token, expiresIn: hh };
  });
};
