import { Button, ErrorMessage, Input, Loading } from 'components';
import React, { useEffect } from 'react';

import LoginImage from '../../assets/images/login.png';
import LogoRiachuelo from '../../assets/icons/riachuelo.svg';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useAuth } from 'AuthHook';

export const LoginRoute: React.FC = () => {
  const methods = useForm({
    defaultValues: { user: '', password: '', authtype: '' },
  });

  const { handleSubmit, register } = methods;

  const { onLogin, token, error, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <div
      className="flex w-full h-screen items-center justify-center bg-[--login-bg] "
      style={{
        backgroundImage: `url(${LoginImage})`,
        backgroundSize: 'cover',
      }}>
      <div className="card w-[572px] bg-white rounded-md">
        <div className="flex flex-row">
          <FormProvider {...methods}>
            <form
              className="card-body text-center relative "
              onSubmit={handleSubmit(data => onLogin(data))}>
              <div>
                {loading && <Loading />}
                <h2 className="card-title mb-16">Acesso</h2>
                <div className="flex my-6">
                  <input
                    {...register('authtype')}
                    required
                    value="authint"
                    type="radio"
                    name="authtype"
                    aria-label="internacional"
                    className="btn btn-outline bg-white p-2 w-full text-sm text-black leading-6 border-solid border-2 border-black rounded-md hover:opacity-.5"
                    title="nacional"
                  />
                  <input
                    {...register('authtype')}
                    required
                    value="authbr"
                    type="radio"
                    name="authtype"
                    aria-label="nacional"
                    className="btn btn-outline ml-4 bg-white p-2 w-full text-sm text-black leading-6 border-solid border-2 border-black rounded-md hover:opacity-.5"
                    title="internacional"
                  />
                </div>

                <Input.Text name="user" label="Usuário" />
                <div className="mt-12">
                  <Input.Password name="password" label="Senha" />
                </div>
                {error && (
                  <div className="flex w-full mt-12">
                    <ErrorMessage message={error} />
                  </div>
                )}

                <div className="flex mt-12 w-32">
                  <Button.Primary type="submit" title="Acessar" />
                </div>
              </div>
            </form>
          </FormProvider>

          <div className="flex grow bg-black justify-center bg-[--login-card-bg] rounded-r-md">
            <img src={LogoRiachuelo} className="w-24" />
          </div>
        </div>
      </div>
    </div>
  );
};
